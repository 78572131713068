<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="About Us" tabActive="Events" src="/images/sub/sv-academy-seminar.png"></sub-visual>
        </template>

        <sub-depth-tab-wrap>
            <v-container>
                <chip-link-group v-bind="{ chips }" exact :tabActive="mode" />
            </v-container>
        </sub-depth-tab-wrap>

        <v-container>
            <v-divider class="border-2 black" />
            <seminar-list-item v-for="item in seminars" :key="item._id" v-bind="{ item }" />
            <pagination-component :count="pageCount" :value="page" @change="(page) => $router.replace({ query: { ...$route.query, page } })" />
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import { STORY_NEWS_CATEGORIES } from "@/assets/variables";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import ChipLinkGroup from "@/components/client/dumb/chip-link-group.vue";
import SeminarListItem from "@/components/client/academy/seminar-list-item.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
const categories = Object.values(STORY_NEWS_CATEGORIES);

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubDepthTabWrap,
        ChipLinkGroup,
        SeminarListItem,
        PaginationComponent,
    },
    data: () => ({
        limit: 5,
        summary: { totalCount: 0 },
        seminars: [],
        categories,
    }),
    computed: {
        mode() {
            return this.$route.query.mode || "upcoming";
        },
        page() {
            return +this.$route.query.page || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        chips() {
            return ["upcoming", "past"].map((item) => ({ text: item, to: { query: { ...this.$route.query, mode: item } } }));
        },
        sort() {
            return JSON.stringify({ datetime: this.mode == "upcoming" ? 1 : -1 });
        },
    },
    mounted() {
        this.syncRoute().then(this.search());
    },
    watch: {
        page() {
            this.syncRoute().then(this.search());
        },
        mode() {
            if (this.page == 1) this.syncRoute().then(this.search());
            else this.$router.replace({ query: { ...this.$route.query, page: undefined } });
        },
    },
    methods: {
        async syncRoute() {
            const { mode } = this;
            if (mode != this.$route.query?.mode) this.$router.replace({ query: { ...this.$route.query, mode } });
        },
        async search() {
            const { skip, limit, sort, mode } = this;
            const { summary, seminars } = await api.v1.academy.seminars.gets({
                headers: { skip, limit, sort },
                params: { mode },
            });
            this.summary = summary;
            this.seminars = seminars;
        },
    },
};
</script>

<style></style>
