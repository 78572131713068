var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shows ? _c('v-row', {
    staticClass: "data-list-item font-size-14 font-size-md-16 font-spoqa grey-16--text",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": _vm.termCols,
      "sm": _vm.termSm,
      "md": _vm.termMd,
      "lg": _vm.termLg,
      "xl": _vm.termXl
    }
  }, [_c('v-row', {
    staticClass: "grey-90--text",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "pr-4"
  }, [_vm._v("·")])]), _c('v-col', [_vm._v(_vm._s(_vm.term))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.descCols,
      "sm": _vm.descSm,
      "md": _vm.descMd,
      "lg": _vm.descLg,
      "xl": _vm.descXl
    }
  }, [this.$slots['descContents'] ? _c('div', {
    staticClass: "pl-12"
  }, [_vm._t("descContents")], 2) : _c('div', {
    staticClass: "pl-12"
  }, [_vm._v(_vm._s(_vm.desc))])])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }