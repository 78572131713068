<template>
    <v-card :to="`${$route.path}/${item?._id}`" flat>
        <v-row no-gutters>
            <v-col cols="12" sm="5" md="3" class="align-self-center align-self-lg-start">
                <div class="py-sm-16 py-lg-30">
                    <v-img :aspect-ratio="1 / 1" :src="item?.thumb?.url">
                        <v-overlay v-if="!item?.thumb?.url" absolute z-index="0">
                            <v-icon large>mdi-image-broken</v-icon>
                        </v-overlay>
                    </v-img>
                </div>
            </v-col>
            <v-col cols="12" sm="7" md="9" align-self="center">
                <div class="pa-16 pr-sm-0 pl-md-30 py-md-30 pl-lg-60 pt-lg-60">
                    <div class="tit tit--xs mb-4 mb-md-6 seminar-list-item__subject">{{ item?.subject }}</div>
                    <div class="font-size-14 font-size-md-16 seminar-list-item__summary">{{ item?.summary }}</div>
                    <v-divider class="my-14 my-md-26" />

                    <template v-for="{ term, desc } in dataList">
                        <data-list-item :key="term" v-bind="{ ...dataListAttrs, term, desc }" />
                    </template>
                    <data-list-item v-if="item?.website" term="Website Link" v-bind="dataListAttrs">
                        <template #descContents>
                            <a :href="item?.website" class="primary--text" target="_blank" @click.stop.capture>{{ item?.website }}</a>
                        </template>
                    </data-list-item>
                </div>
            </v-col>
        </v-row>
        <v-divider />
    </v-card>
</template>

<script>
import { STORY_NEWS_CATEGORIES } from "@/assets/variables";

import DataListItem from "@/components/dumb/data-list-item.vue";

const dataListAttrs = {
    termCols: "auto",
    termSm: "auto",
    termMd: "5",
    termLg: "4",
    termXl: "3",
    descCols: "auto",
    descSm: "auto",
    descMd: "auto",
    descLg: "auto",
    descXl: "auto",
};

export default {
    components: {
        DataListItem,
    },
    props: {
        item: { type: Object, default: () => ({}) },
    },
    data: () => ({
        dataListAttrs,
    }),
    computed: {
        dataList() {
            return [
                { term: "Category", desc: STORY_NEWS_CATEGORIES[this.item?.category]?.text },
                { term: "Held at", desc: this.item?.datetime?.toDateTime?.() },
                { term: "Nation", desc: this.item?.country },
                { term: "EventLocation", desc: this.item?.location },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.seminar-list-item{
    &__subject{
        white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
    }
    &__summary{
        max-height: 3em;
        overflow: hidden; text-align: left; text-overflow: ellipsis; white-space: normal; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
    }
}
</style>
