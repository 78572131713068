var render = function render(){
  var _vm$item, _vm$item2, _vm$item2$thumb, _vm$item3, _vm$item3$thumb, _vm$item4, _vm$item5, _vm$item6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "to": `${_vm.$route.path}/${(_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item._id}`,
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "align-self-center align-self-lg-start",
    attrs: {
      "cols": "12",
      "sm": "5",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "py-sm-16 py-lg-30"
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "src": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$thumb = _vm$item2.thumb) === null || _vm$item2$thumb === void 0 ? void 0 : _vm$item2$thumb.url
    }
  }, [!((_vm$item3 = _vm.item) !== null && _vm$item3 !== void 0 && (_vm$item3$thumb = _vm$item3.thumb) !== null && _vm$item3$thumb !== void 0 && _vm$item3$thumb.url) ? _c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-image-broken")])], 1) : _vm._e()], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7",
      "md": "9",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "pa-16 pr-sm-0 pl-md-30 py-md-30 pl-lg-60 pt-lg-60"
  }, [_c('div', {
    staticClass: "tit tit--xs mb-4 mb-md-6 seminar-list-item__subject"
  }, [_vm._v(_vm._s((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.subject))]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 seminar-list-item__summary"
  }, [_vm._v(_vm._s((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.summary))]), _c('v-divider', {
    staticClass: "my-14 my-md-26"
  }), _vm._l(_vm.dataList, function (_ref) {
    var term = _ref.term,
      desc = _ref.desc;
    return [_c('data-list-item', _vm._b({
      key: term
    }, 'data-list-item', Object.assign({}, _vm.dataListAttrs, {
      term,
      desc
    }), false))];
  }), (_vm$item6 = _vm.item) !== null && _vm$item6 !== void 0 && _vm$item6.website ? _c('data-list-item', _vm._b({
    attrs: {
      "term": "Website Link"
    },
    scopedSlots: _vm._u([{
      key: "descContents",
      fn: function () {
        var _vm$item7, _vm$item8;
        return [_c('a', {
          staticClass: "primary--text",
          attrs: {
            "href": (_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.website,
            "target": "_blank"
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(_vm._s((_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : _vm$item8.website))])];
      },
      proxy: true
    }], null, false, 134429616)
  }, 'data-list-item', _vm.dataListAttrs, false)) : _vm._e()], 2)])], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }