var render = function render(){
  var _vm$seminar, _vm$seminar$thumb, _vm$seminar2, _vm$seminar2$thumb, _vm$seminar3, _vm$seminar4, _vm$seminar7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "About Us",
            "tabActive": "Events",
            "src": "/images/sub/sv-academy-seminar.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-expand-transition', [_c('v-container', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.seminar,
      expression: "seminar"
    }]
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    staticClass: "border",
    attrs: {
      "contain": "",
      "aspect-ratio": 1 / 1,
      "src": (_vm$seminar = _vm.seminar) === null || _vm$seminar === void 0 ? void 0 : (_vm$seminar$thumb = _vm$seminar.thumb) === null || _vm$seminar$thumb === void 0 ? void 0 : _vm$seminar$thumb.url
    }
  }, [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$seminar2 = _vm.seminar) !== null && _vm$seminar2 !== void 0 && (_vm$seminar2$thumb = _vm$seminar2.thumb) !== null && _vm$seminar2$thumb !== void 0 && _vm$seminar2$thumb.url),
      expression: "!seminar?.thumb?.url"
    }],
    attrs: {
      "absolute": "",
      "z-index": "0"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-image-broken")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pt-20 pt-md-0 pl-lg-40"
  }, [_c('v-divider', {
    staticClass: "border-2 secondary"
  }), _c('div', {
    staticClass: "py-20 py-md-30"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v(_vm._s((_vm$seminar3 = _vm.seminar) === null || _vm$seminar3 === void 0 ? void 0 : _vm$seminar3.subject))])]), _c('v-divider'), _c('div', {
    staticClass: "py-20 py-md-30"
  }, [_vm._l(_vm.dataList, function (_ref) {
    var term = _ref.term,
      desc = _ref.desc;
    return [_c('data-list-item', _vm._b({
      key: term
    }, 'data-list-item', Object.assign({}, _vm.dataListAttrs, {
      term,
      desc
    }), false))];
  }), (_vm$seminar4 = _vm.seminar) !== null && _vm$seminar4 !== void 0 && _vm$seminar4.website ? _c('data-list-item', _vm._b({
    attrs: {
      "term": "Website Link"
    },
    scopedSlots: _vm._u([{
      key: "descContents",
      fn: function () {
        var _vm$seminar5, _vm$seminar6;
        return [_c('a', {
          staticClass: "primary--text",
          attrs: {
            "href": (_vm$seminar5 = _vm.seminar) === null || _vm$seminar5 === void 0 ? void 0 : _vm$seminar5.website,
            "target": "_blank"
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(_vm._s((_vm$seminar6 = _vm.seminar) === null || _vm$seminar6 === void 0 ? void 0 : _vm$seminar6.website))])];
      },
      proxy: true
    }], null, false, 4205921584)
  }, 'data-list-item', _vm.dataListAttrs, false)) : _vm._e()], 2), _c('v-divider')], 1)])], 1), _c('div', {
    staticClass: "pt-60 pt-md-80 pt-lg-100 pb-40 pb-md-60"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen mb-16 mb-md-30"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("News detail")])]), _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$seminar7 = _vm.seminar) === null || _vm$seminar7 === void 0 ? void 0 : _vm$seminar7.content)
    }
  })]), _c('v-divider'), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-120px",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("List")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }