var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "About Us",
            "tabActive": "Events",
            "src": "/images/sub/sv-academy-seminar.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-depth-tab-wrap', [_c('v-container', [_c('chip-link-group', _vm._b({
    attrs: {
      "exact": "",
      "tabActive": _vm.mode
    }
  }, 'chip-link-group', {
    chips: _vm.chips
  }, false))], 1)], 1), _c('v-container', [_c('v-divider', {
    staticClass: "border-2 black"
  }), _vm._l(_vm.seminars, function (item) {
    return _c('seminar-list-item', _vm._b({
      key: item._id
    }, 'seminar-list-item', {
      item
    }, false));
  }), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount,
      "value": _vm.page
    },
    on: {
      "change": function (page) {
        return _vm.$router.replace({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }