<template>
    <v-row v-if="shows" no-gutters class="data-list-item font-size-14 font-size-md-16 font-spoqa grey-16--text">
        <v-col :cols="termCols" :sm="termSm" :md="termMd" :lg="termLg" :xl="termXl">
            <v-row no-gutters align="center" class="grey-90--text">
                <v-col cols="auto"><span class="pr-4">·</span></v-col>
                <v-col>{{ term }}</v-col>
            </v-row>
        </v-col>
        <v-col :cols="descCols" :sm="descSm" :md="descMd" :lg="descLg" :xl="descXl">
            <div v-if="this.$slots['descContents']" class="pl-12">
                <slot name="descContents" />
            </div>
            <div v-else class="pl-12">{{ desc }}</div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        termCols: { type: String, default: "12" },
        termSm: { type: String, default: "3" },
        termMd: { type: String, default: "3" },
        termLg: { type: String, default: "2" },
        termXl: { type: String, default: "2" },
        descCols: { type: String, default: "12" },
        descSm: { type: String, default: "9" },
        descMd: { type: String, default: "9" },
        descLg: { type: String, default: "10" },
        descXl: { type: String, default: "10" },
        term: { type: String, default: "" },
        desc: { type: String, default: "" },
        showIfDesc: { type: Boolean, default: false },
    },
    computed: {
        shows() {
            return !(this.showIfDesc && !this.desc);
        },
    },
};
</script>

<style lang="scss" scoped>
.data-list-item {
    line-height: 1.5;
    &:not(:first-child) {
        margin-top: 6px !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .data-list-item {
        &:not(:first-child) {
            margin-top: 12px !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
