<template>
    <section class="page-section">
        <v-container v-if="this.$slots['default']">
            <slot />
        </v-container>
        <slot v-if="this.$slots['pageSectionImmersive']" name="pageSectionImmersive" />
    </section>
</template>

<script>
export default{
    props : {
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
.page-section{
    position:relative;
    padding: 60px 0;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.page-section--first{
    padding-top: 60px;
    margin-top: -60px;
}
.page-section--last{
    padding-bottom: 60px;
    margin-bottom: -60px;
}
.page-section__bg{
    position: absolute;
    top:0;
    left:50%;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    z-index: -1;
    display: block;
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .page-section{
        padding: 80px 0;
    }
    .page-section--first{
        padding-top: 80px;
        margin-top: -80px;
    }
    .page-section--last{
        padding-bottom: 80px;
        margin-bottom: -80px;
    }
}
@media (min-width:1024px){
    .page-section{
        padding: 120px 0;
    }
    .page-section--first{
        padding-top: 120px;
        margin-top: -120px;
    }
    .page-section--last{
        padding-bottom: 120px;
        margin-bottom: -120px;
    }
}
@media (min-width:1200px){
}
</style>