<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="About Us" tabActive="Events" src="/images/sub/sv-academy-seminar.png"></sub-visual>
        </template>

        <v-expand-transition>
            <v-container v-show="seminar">
                <v-row>
                    <v-col cols="12" md="5">
                        <v-img contain :aspect-ratio="1 / 1" :src="seminar?.thumb?.url" class="border">
                            <v-fade-transition>
                                <v-overlay v-show="!seminar?.thumb?.url" absolute z-index="0">
                                    <v-icon large>mdi-image-broken</v-icon>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-col>
                    <v-col cols="12" md="7">
                        <div class="pt-20 pt-md-0 pl-lg-40">
                            <v-divider class="border-2 secondary" />
                            <div class="py-20 py-md-30">
                                <h3 class="tit tit--xs">{{ seminar?.subject }}</h3>
                            </div>
                            <v-divider />
                            <div class="py-20 py-md-30">
                                <template v-for="{ term, desc } in dataList">
                                    <data-list-item :key="term" v-bind="{ ...dataListAttrs, term, desc }" />
                                </template>
                                <data-list-item v-if="seminar?.website" term="Website Link" v-bind="dataListAttrs">
                                    <template #descContents>
                                        <a :href="seminar?.website" class="primary--text" target="_blank" @click.stop.capture>{{ seminar?.website }}</a>
                                    </template>
                                </data-list-item>
                            </div>
                            <v-divider />
                        </div>
                    </v-col>
                </v-row>

                <div class="pt-60 pt-md-80 pt-lg-100 pb-40 pb-md-60">
                    <div class="tit-wrap tit-wrap--hyphen mb-16 mb-md-30">
                        <h2 class="tit tit--xs">News detail</h2>
                    </div>
                    <div v-html="seminar?.content" />
                </div>

                <v-divider />
                <div class="mt-20 mt-md-30">
                    <v-row justify="end">
                        <v-col cols="auto">
                            <v-btn small outlined color="grey-d" class="w-120px" @click="$router.go(-1)">List</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-expand-transition>
    </client-page>
</template>

<script>
import api from "@/api";
import { STORY_NEWS_CATEGORIES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import DataListItem from "@/components/dumb/data-list-item.vue";

const dataListAttrs = {
    termCols: "auto",
    termSm: "auto",
    termMd: "5",
    termLg: "4",
    termXl: "3",
    descCols: "auto",
    descSm: "auto",
    descMd: "auto",
    descLg: "auto",
    descXl: "auto",
};

export default {
    components: {
        ClientPage,
        SubVisual,
        DataListItem,
    },
    props: {
        _seminar: { type: String, default: null },
    },
    data: () => ({
        seminar: undefined,

        dataListAttrs,
    }),
    computed: {
        dataList() {
            return [
                { term: "Category", desc: STORY_NEWS_CATEGORIES[this.seminar?.category]?.text },
                { term: "Held at", desc: this.seminar?.datetime?.toDateTime?.() },
                { term: "Nation", desc: this.seminar?.country },
                { term: "EventLocation", desc: this.seminar?.location },
            ];
        },
    },
    mounted() {
        try {
            if (!this._seminar) throw new Error("잘못된 요청입니다.");
            this.init();
        } catch (error) {
            alert(error?.message);
            (async () => this.$router.go(-1))().then(() => this.$router.replace("/"));
        }
    },
    methods: {
        async init() {
            this.seminar = (await api.v1.academy.seminars.get({ _id: this._seminar }))?.seminar;
        },
    },
};
</script>

<style scoped></style>
